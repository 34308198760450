<template>
  <div class="blog container" v-if="posts && !isLoading">
    <section class="blog__header box">
      <h1>Posts</h1>
    </section>
    <section class="blog__list box">
      <card-post
        v-for="(post, index) in posts"
        :key="post.id"
        :post-id="post.id"
        :post-number="index + 1"
        :post-img="post.feature_image"
        :post-title="post.title"
        :post-date="post.published_at"
        :post-author="post.slug"
        :post-reading-time="post.reading_time"
      >
      </card-post>
    </section>
  </div>
</template>

<script>
import ghostApi from "@/service/ghostApi.js";
import CardPost from "@/components/Blog/CardPost.vue";

export default {
  components: { CardPost },
  data() {
    return {
      posts: [],
      isLoading: false,
    };
  },
  async mounted() {
    await this.getAllPosts();
  },
  methods: {
    async getAllPosts() {
      this.isLoading = true;
      try {
        const { data } = await ghostApi.posts.getAll();
        this.posts = data;
      }catch(error){
        console.error(error)
      } finally {
        this.isLoading = false; 
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blog {
  display: grid;
  grid-template-rows: (2, 1fr);
  row-gap: 16px;
  .box {
    padding: 16px;
  }
  &.container {
    max-width: 1200px;
    margin: 42px auto;
  }
  &__header {
    h1 {
      margin: 0px;
      line-height: 46px;
      font-style: italic;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    row-gap: 32px;
  }
}
@include respond-to("medium and down") {
  .blog {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@include respond-to("xsmall and down") {
  .blog {
    row-gap: 0px;
    &.container {
      margin: 0px auto;
    }
    &__list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
