<template>
  <div class="card__post">
    <div class="card__post__img">
      <router-link
        :to="{
          name: 'post-detail',
          params: { postId: postId },
        }"
      >
        <img :src="postImg" alt="Thumbnail Image" />
      </router-link>
    </div>
    <div class="card__post__footer">
      <h3>#{{ postNumber }} {{ postTitle }}</h3>
      <span> {{ moment(postDate).format("MMMM DD, YYYY") }}</span>
      <span> <strong>By: </strong>Team Emprops</span>
      <span>{{ postReadingTime }} min</span>
      <router-link
        :to="{
          name: 'post-detail',
          params: { postId: postId },
        }"
      >
        Read all article
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    postId: {
      type: String,
      required: true,
    },
    postNumber: {
      type: Number,
      required: true,
    },
    postImg: {
      type: String,
      required: true,
    },
    postTitle: {
      type: String,
      required: true,
    },
    postDate: {
      type: String,
      required: true,
    },
    postAuthor: {
      type: String,
      required: true,
    },
    postReadingTime: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.card__post {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &__img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    background-color: transparent;
    span {
      line-height: 24px;
      strong {
        font-weight: 700;
        margin-right: 4px;
      }
    }
    a {
      color: var(--color-emerald);
      text-decoration: none;
      line-height: 18px;
    }
  }
}
</style>
